import React, { useState } from "react"

import { Layout2 } from "../components/layout2"
import { InputField } from "./request-demo"

export default function FreeDomain() {
  const [show, setShow] = useState(false)
  return (
    <Layout2>
      <div className="hidden md:flex overflow-hidden relative  items-center">
        <div className="">
          <img
            className="w-full object-right"
            src={require("./../assets/woman-black (1).png")}
          />
        </div>

        <div className="container text-white absolute z-0">
          <div className="max-w-md mt-8 lg:mt-0 ml-16">
            <h1 className="text-5xl font-black leading-tight">
              Get a free domain & email for your new business
            </h1>
            <p className="font-bold text-2xl mt-5 mb-6">
              Are you a new business?
            </p>
            <p className="mb-4">
              Touchcore is offering you a free domain name and email for your
              business.
            </p>
            <button
              onClick={() => setShow(!show)}
              className="bg-pred px-20 py-2 font-bold"
            >
              APPLY
            </button>
          </div>
        </div>
        {show && (
          <div className="fixed right-0 top-0 mt-16 mr-20">
            <ApplyForm />
          </div>
        )}
      </div>
      <div className="md:hidden mt-16">
        <div className="text-center pt-6 w-10/12 mx-auto">
          <h1 className="text-lg font-black leading-tight">
            Apply for a free domain & email for your new business
          </h1>
          <p className="font-bold text-sm mt-3 ">Are you a new business?</p>
          <p className="mb- text-xs">
            Touchcore is offering you a free domain name and email for your
            business.
          </p>
        </div>
        <ApplyForm />
      </div>
    </Layout2>
  )
}

const ApplyForm = () => (
  <div className="shadow-md h-screen  md:overflow-auto bg-white md:w-96  ">
    <form
      action="https://touchcore.us17.list-manage.com/subscribe/post"
      method="POST"
      className="h-full px-5 md:px-10 py-8 md:overflow-auto"
    >
      <input type="hidden" name="u" value="ac0a245d515c7b432c9d8d6f5" />
      <input type="hidden" name="id" value="86a635759d" />
      <input type="hidden" name="MERGE6" value="Free Domain + Business email" />
      <InputField name="MERGE1" label="First Name" />
      <InputField name="MERGE2" label="Last Name" />
      <InputField name="MERGE0" label="Email" />
      <InputField name="MERGE4" label="Phone No" />
      <InputField name="MERGE8" label="Company Name" />

      <button className="py-3 mb-5 bg-pred rounded-sm block w-full text-white font-semibold">
        APPLY
      </button>
    </form>
  </div>
)
